import { Observable, filter, from, lastValueFrom, of, switchMap, take, tap } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { LightboxDetailsDto } from '@cgib/shared/dtos/lightbox/lightbox-details.dto';
import { LightboxIdsDto } from '@cgib/shared/dtos/lightbox/lightbox-ids.dto';
import { LightboxItemDto } from '@cgib/shared/dtos/lightbox/lightboxItem.dto';
import { UpdateTypeEnum } from '@cgib/shared/enums/update-type.enum';
import { MemoizedObservable } from '@cgib/shared/helper/memoized-observable';
import { AsyncLightboxRepository } from '@cgib/ui-shared/repositories/async-lightbox.repository';

import { LightboxGraphqlService } from '../graphql/graphql-lightbox.service';
import { UserGraphqlService } from '../graphql/graphql-user.service';
import { UserService } from '../user.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class SyncLightboxService {
    userService = inject(UserService);
    graphqlService = inject(UserGraphqlService);
    lightboxGraphqlService = inject(LightboxGraphqlService);
    lightboxRepository = inject(AsyncLightboxRepository);

    async setActiveLightbox(lightboxId: string | undefined) {
        this.lightboxRepository.setActiveLightboxId(lightboxId);
        console.log('lightbox', lightboxId);
        return lastValueFrom(this.graphqlService.updateUserSettings$({ activatedLightboxId: lightboxId ?? '' }));
    }

    @MemoizedObservable()
    subcribeToLightboxUpdates$() {
        return this.userService.isAuthenticated$().pipe(
            filter(authenticated => !!authenticated),
            take(1),
            switchMap(() => this.lightboxGraphqlService.subscribeToLightboxUpdate$()),
            switchMap(update => {
                if (update?.updateType === UpdateTypeEnum.CREATE) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    return of(this.setActiveLightbox(update.lightbox!.id));
                } else if (update?.updateType === UpdateTypeEnum.DELETE && update?.totalLightboxes === 0) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    return of(this.setActiveLightbox(undefined));
                } else if (update?.lightboxIds?.some(id => id === this.lightboxRepository.getActiveLightboxId())) {
                    return from(
                        this.setActiveLightbox(
                            this.lightboxRepository
                                .getPartialLightboxes()
                                ?.filter(partial => !update.lightboxIds?.includes(partial.id))?.[0]?.id
                        )
                    );
                }
                return of(void 0);
            })
        );
    }
    @MemoizedObservable()
    getActivatedLightboxId$(): Observable<string | undefined> {
        return this.lightboxRepository.getActivatedLightboxId$();
    }

    @MemoizedObservable()
    getLightboxImageIds$(): Observable<string[]> {
        return this.lightboxRepository.getLightboxImageIds$();
    }

    @MemoizedObservable()
    getLightboxSceneIds$(): Observable<string[]> {
        return this.lightboxRepository.getLightboxSceneIds$();
    }

    @MemoizedObservable()
    getLightbox3dBackplateIds$(): Observable<string[]> {
        return this.lightboxRepository.getLightbox3dBackplateIds$();
    }

    @MemoizedObservable()
    getLightboxDomeIds$(): Observable<string[]> {
        return this.lightboxRepository.getLightboxDomeIds$();
    }

    @MemoizedObservable()
    getLightboxLocationIds$(): Observable<string[]> {
        return this.lightboxRepository.getLightboxLocationIds$();
    }

    @MemoizedObservable()
    getPartialLightboxes$() {
        return this.lightboxRepository.getPartialLightboxes$();
    }

    fetchLightboxIds$(lightboxId: string): Observable<LightboxIdsDto> {
        return this.lightboxGraphqlService.getLightboxItemIds$(lightboxId);
    }

    fetchPartialLightboxes$(): Observable<Pick<LightboxDetailsDto, 'id' | 'name'>[]> {
        return this.userService.isAuthenticated$().pipe(
            switchMap(isAuthenticated => {
                if (isAuthenticated) {
                    return this.lightboxGraphqlService
                        .getPartialLightboxes$()
                        .pipe(tap(lightboxes => this.lightboxRepository.setPartialLightboxes(lightboxes)));
                } else {
                    return of([]);
                }
            })
        );
    }

    addLightboxItem(id: LightboxItemDto) {
        this.lightboxRepository.addItemsToActiveLightbox([id]);
    }
}
